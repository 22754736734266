<template>
  <v-container
    id="product-search"
    fluid
    tag="section"
  >
    <base-material-card
      color="indigo"
      icon="mdi-layers-search"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Search Products
        </div>
      </template>

      <v-expansion-panels
        v-model="openPanel"
      >
        <v-expansion-panel>
          <v-expansion-panel-header
            v-if="$vuetify.breakpoint.mobile"
            v-slot="{ open }"
          >
            <v-row no-gutters>
              <v-col cols="4">
                Search Parameters
                <span
                  v-if="!open"
                  class="text--secondary"
                >
                  (
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                  edit)
                </span>
              </v-col>
              <v-col
                cols="8"
                class="text--secondary"
              >
                <v-fade-transition leave-absolute>
                  <v-row
                    v-if="!open"
                    no-gutters
                    style="width: 100%"
                  >
                    <v-col
                      v-if="description"
                      cols="12"
                    >
                      Description: {{ description || 'Not set' }}
                    </v-col>
                    <v-col
                      v-if="code"
                      cols="12"
                    >
                      Code: {{ code || 'Not set' }}
                    </v-col>
                    <v-col
                      v-if="collection"
                      cols="12"
                    >
                      Collection: {{ parametersDomains.CAMPIONARIO.filter(i => i.Code === collection)[0].Description || 'Not set' }}
                    </v-col>
                    <v-col
                      v-if="subCollection"
                      cols="12"
                    >
                      Sub-Collection: {{ parametersDomains.SOTTOCAMPIONARIO.filter(i => i.Code === subCollection)[0].Description || 'Not set' }}
                    </v-col>
                    <v-col
                      v-if="finishing"
                      cols="12"
                    >
                      Finishing: {{ parametersDomains.FINISHING.filter(i => i.Code === finishing)[0].Description || 'Not set' }}
                    </v-col>
                    <v-col
                      v-if="colorsGroups && colorsGroups.length"
                      cols="12"
                    >
                      Colors Groups: {{ colorsGroups.map(c => parametersDomains.FAMIGLIACOLORE.filter(i => i.Code === c)[0].English) || 'Not set' }}
                    </v-col>
                    <v-col
                      v-if="thickness && thickness.length && (thickness[0] !== parseFloat($refs.thicknessSlider.min) || thickness[1] !== parseFloat($refs.thicknessSlider.max))"
                      cols="12"
                    >
                      Thickness: {{ thickness || 'Not set' }}
                    </v-col>
                  </v-row>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <div
              v-if="loadingParameters"
              class="text-center"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>
            <v-form
              v-if="!loadingParameters"
              ref="form"
              class="pt-1"
            >
              <v-row
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="description"
                    clearable
                    label="Description"
                    outlined
                    hide-details
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="code"
                    clearable
                    label="Code"
                    outlined
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="collection"
                    clearable
                    label="Collection"
                    :items="parametersDomains.CAMPIONARIO"
                    item-text="Description"
                    item-value="Code"
                    :disabled="refiningParameters || !parametersDomains.CAMPIONARIO"
                    :loading="refiningParameters"
                    outlined
                    hide-details
                    @change="filterParameters"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="subCollection"
                    clearable
                    label="Sub-Collection"
                    :items="collection != null ? parametersDomains.SOTTOCAMPIONARIO : []"
                    item-text="Description"
                    item-value="Code"
                    :disabled="collection == null || refiningParameters || !parametersDomains.SOTTOCAMPIONARIO || parametersDomains.SOTTOCAMPIONARIO.length === 0"
                    :loading="refiningParameters"
                    outlined
                    hide-details
                    @change="filterParameters"
                  />
                </v-col>
              </v-row>
              <v-row
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="finishing"
                    clearable
                    label="Finishing"
                    :items="parametersDomains.FINISHING"
                    item-text="Description"
                    item-value="Code"
                    :disabled="refiningParameters || !parametersDomains.FINISHING"
                    :loading="refiningParameters"
                    outlined
                    hide-details
                    @change="filterParameters"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="colorsGroups"
                    clearable
                    label="Colors Group"
                    multiple
                    :chips="true"
                    :deletable-chips="true"
                    :small-chips="true"
                    :hide-selected="true"
                    :items="parametersDomains.FAMIGLIACOLORE"
                    item-text="English"
                    item-value="Code"
                    :item-disabled="(item) => colorsGroups && colorsGroups.length >= 5 && !colorsGroups.includes(item.Code)"
                    :disabled="refiningParameters || !parametersDomains.FAMIGLIACOLORE"
                    :loading="refiningParameters"
                    outlined
                    hide-details
                    :menu-props="{
                      closeOnContentClick: true,
                    }"
                    @change="filterParameters"
                  />
                </v-col>
              </v-row>
              <v-row
                align="center"
              >
                <v-col
                  cols="12"
                >
                  <v-range-slider
                    ref="thicknessSlider"
                    v-model="thickness"
                    :min="parametersDomains.SPESSORE ? parametersDomains.SPESSORE[0].Code : 0"
                    :max="parametersDomains.SPESSORE ? parametersDomains.SPESSORE[parametersDomains.SPESSORE.length - 1].Code : 0"
                    step="0.1"
                    :disabled="refiningParameters"
                    :loading="refiningParameters"
                    hide-details
                  >
                    <template v-slot:label>
                      <span v-html="!$vuetify.breakpoint.mobile ? 'Thickness (mm)' : 'Thickness<br>(mm)' " />
                    </template>
                    <template v-slot:thumb-label="props">
                      {{ props.value }}
                    </template>
                    <template
                      v-if="!$vuetify.breakpoint.mobile"
                      v-slot:append
                    >
                      <div
                        style="white-space: nowrap"
                        class="v-label text--secondary"
                      >
                        {{ thickness[0] }} - {{ thickness[1] }} mm
                      </div>
                    </template>
                  </v-range-slider>
                </v-col>
              </v-row>
              <v-row
                align="center"
              >
                <v-col
                  cols="12"
                >
                  <v-slider
                    v-model="minQtyAvailable"
                    min="1"
                    :max="qtyTicksLabels.length"
                    step="1"
                    ticks="always"
                    tick-size="5"
                    :tick-labels="qtyTicksLabels"
                    @change="filterParameters"
                  >
                    <template v-slot:label>
                      <span v-html="!$vuetify.breakpoint.mobile ? 'Minimum quantity available (MTL)' : 'Qty Avail.<br>(MTL)' " />
                    </template>
                  </v-slider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-tooltip
                  v-if="noSearchParams"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        color="primary"
                        class="text-right"
                        :disabled="noSearchParams"
                        @click="runSearch"
                      >
                        Search
                      </v-btn>
                    </div>
                  </template>
                  <span>Select at least one parameter</span>
                </v-tooltip>
                <v-btn
                  v-else
                  color="primary"
                  class="text-right"
                  :disabled="noSearchParams"
                  @click="runSearch"
                >
                  Search
                </v-btn>
                <v-btn
                  color="default"
                  class="ml-4"
                  @click="reset"
                >
                  Reset
                </v-btn>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-text-field
        v-if="!loadingParameters"
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto"
        label="Refine Search"
        hide-details
        single-line
        style="max-width: 250px;"
      />

      <v-divider
        v-if="!loadingParameters"
        class="mt-3"
      />

      <v-data-table
        v-if="!loadingParameters"
        :headers="headers"
        :items="itemsWithLink"
        :search.sync="search"
        multi-sort
        :loading="loading"
        @click:row="handleClick"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            fab
            small
            @click:row="handleClick(item)"
          >
            <v-icon
              color="primary"
            >
              mdi-arrow-top-right-thick
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import { API } from 'aws-amplify'
  import * as _ from 'underscore'

  export default {
    name: 'DashboardDataTables',

    data: () => ({
      openPanel: 0,
      loading: false,
      loadingParameters: true,
      refiningParameters: false,
      parametersDomains: [],
      code: null,
      description: null,
      collection: null,
      subCollection: null,
      colorsGroups: null,
      thickness: ['-999', '999'],
      minQtyAvailable: 1,
      qtyTicksLabels: [
        '0',
        '100',
        '500',
        '1000',
      ],
      finishing: null,
      headers: [
        {
          text: 'Description',
          value: 'Item_Description',
        },
        {
          text: 'Code',
          value: 'Item_Code',
        },
        {
          text: 'Height',
          value: 'Item_Height',
        },
        {
          text: 'Weight',
          value: 'Item_Weight',
        },
        {
          text: 'Thickness',
          value: 'Item_Thickness',
        },
        {
          sortable: false,
          text: 'Colors',
          value: 'Item_NumberOfColorsText',
        },
        {
          sortable: false,
          text: 'Actions',
          value: 'actions',
        },
      ],
      items: [],
      search: undefined,
    }),

    computed: {
      itemsWithLink: function () {
        return this.items.map(i => ({
          ...i,
          Item_Link: '/products/'.concat(i.Item_Code),
          Item_NumberOfColorsText: i.Item_NumberOfColors + ' '.concat(this.$tc('colors available', i.Item_NumberOfColors)),
        }))
      },
      noSearchParams: function () {
        return !this.description && !this.code && !this.collection && !this.subCollection && !this.finishing && (!this.colorsGroups || (this.colorsGroups && this.colorsGroups.length === 0))
      },
    },

    created: function () {
      this.loadParameters()
    },

    methods: {
      async loadParameters (refine = false) {
        this.loadingParameters = !refine
        this.refiningParameters = refine
        const apiName = 'apiPublic'
        const path = '/execute/b2b@allusedparameters'
        const myInit = {
          queryStringParameters: {
            ITEMCODEPARMB2B: null,
            DESCRIPTIONPARMB2B: null,
            FAMIGLIACOLOREPARM1B2B: this.colorsGroups ? this.colorsGroups[0] : null,
            FAMIGLIACOLOREPARM2B2B: this.colorsGroups ? this.colorsGroups[1] : null,
            FAMIGLIACOLOREPARM3B2B: this.colorsGroups ? this.colorsGroups[2] : null,
            FAMIGLIACOLOREPARM4B2B: this.colorsGroups ? this.colorsGroups[3] : null,
            FAMIGLIACOLOREPARM5B2B: this.colorsGroups ? this.colorsGroups[4] : null,
            SPESSOREMINPARMB2B: -999,
            SPESSOREMAXPARMB2B: 999,
            FINISHINGPARMB2B: this.finishing,
            CAMPIONARIOPARMB2B: this.collection,
            SOTTOCAMPIONARIOPARMB2B: this.subCollection,
            QUANTITAMINIMAPARMB2B: this.qtyTicksLabels[this.minQtyAvailable - 1],
          },
        }

        API
          .get(apiName, path, myInit)
          .then(response => {
            // console.log(response)
            const parameters = response.recordset
            const groupedParameters = _.groupBy(parameters, 'TIPO_PARAMETRO')
            // const subCollections = _.groupBy(_.sortBy(groupedParameters.SOTTOCAMPIONARIO, (i) => ('' + i.Description).toUpperCase()), 'MasterCode')
            groupedParameters.SOTTOCAMPIONARIO = _.sortBy(groupedParameters.SOTTOCAMPIONARIO, (i) => ('' + i.Description).toUpperCase())
            groupedParameters.CAMPIONARIO = _.sortBy(groupedParameters.CAMPIONARIO, (i) => ('' + i.Description).toUpperCase())
            groupedParameters.FINISHING = _.sortBy(groupedParameters.FINISHING, (i) => ('' + i.Description).toUpperCase())
            groupedParameters.FAMIGLIACOLORE = _.sortBy(groupedParameters.FAMIGLIACOLORE, (i) => ('' + i.English).toUpperCase())
            // console.log(groupedParameters)
            this.parametersDomains = groupedParameters
            if (this.parametersDomains.SPESSORE) {
              this.thickness = [this.parametersDomains.SPESSORE[0].Code, this.parametersDomains.SPESSORE[this.parametersDomains.SPESSORE.length - 1].Code]
            }
            // console.log(groupedParameters)
            // console.log(this.parametersDomains)
            // console.log(this.parametersDomains.CAMPIONARIO)
            this.refiningParameters = this.loadingParameters = false
          })
          .catch(error => {
            this.refiningParameters = this.loadingParameters = false
            /* eslint no-console: 0 */
            console.log(error.response)
          })
      },
      async filterParameters (event) {
        this.loadParameters(true)
      },
      handleClick (row) {
        window.open(row.Item_Link + (this.qtyTicksLabels[this.minQtyAvailable - 1] > 1 ? `?minQty=${this.qtyTicksLabels[this.minQtyAvailable - 1]}` : ''), '_blank')
      },
      reset () {
        this.items = []
        this.$refs.form.reset()
        this.loadParameters()
      },
      async runSearch (event) {
        this.loading = true
        this.items = []
        const apiName = 'apiPublic'
        const path = '/execute/b2b@itemsearch'
        const myInit = {
          queryStringParameters: {
            ITEMCODEPARMB2B: this.code,
            DESCRIPTIONPARMB2B: this.description,
            FAMIGLIACOLOREPARM1B2B: this.colorsGroups ? this.colorsGroups[0] : null,
            FAMIGLIACOLOREPARM2B2B: this.colorsGroups ? this.colorsGroups[1] : null,
            FAMIGLIACOLOREPARM3B2B: this.colorsGroups ? this.colorsGroups[2] : null,
            FAMIGLIACOLOREPARM4B2B: this.colorsGroups ? this.colorsGroups[3] : null,
            FAMIGLIACOLOREPARM5B2B: this.colorsGroups ? this.colorsGroups[4] : null,
            SPESSOREMINPARMB2B: this.thickness[0] ? this.thickness[0] : null,
            SPESSOREMAXPARMB2B: this.thickness[1] ? this.thickness[1] : null,
            FINISHINGPARMB2B: this.finishing,
            CAMPIONARIOPARMB2B: this.collection,
            SOTTOCAMPIONARIOPARMB2B: this.subCollection,
            QUANTITAMINIMAPARMB2B: this.qtyTicksLabels[this.minQtyAvailable - 1],
          },
        }

        API
          .get(apiName, path, myInit)
          .then(response => {
            // console.log(response)
            this.items = response.recordset
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            /* eslint no-console: 0 */
            console.log(error.response)
          })
      },
    },
  }
</script>
